const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#B7001F", hover: "#FF0231" },
    secondary: { main: "#B7001F", hover: "#FF0231" },
    accent: "#B7001F",
    info: {
      main: "#B7001F",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
