import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Baburchi is a popular Indian and Bangladeshi restaurant located in
        Ashford, UK. With a warm and welcoming atmosphere, the restaurant offers
        a wide variety of dishes ranging from traditional curries to tandoori,
        biryanis, and vegetarian options. The menu is prepared using the finest
        and freshest ingredients, ensuring that customers receive the best
        possible dining experience.
      </Typography>
      <Typography variant="body1" paragraph>
        The chefs at Baburchi are highly skilled and experienced, producing
        dishes that are not only delicious but also beautifully presented. The
        restaurant's decor is adorned with traditional artwork and warm
        lighting, creating a cozy and intimate atmosphere. The staff at Baburchi
        are friendly and attentive, ensuring that customers receive excellent
        service throughout their dining experience. The restaurant also offers
        takeaway and delivery services, making it convenient for customers to
        enjoy their favorite dishes at home. Overall, Baburchi is a great choice
        for anyone looking to experience authentic Indian and Bangladeshi
        cuisine in Ashford.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
